import React from 'react';
import { Alert } from 'react-bootstrap';
import FloatingLabelInputField from 'components/shared/floating_label_input_field';
import KapowImage from '../../../assets/images/kapow_hello_logo';

import 'styles/users/sign_in_up.scss'

export default class SignIn extends React.Component {
  constructor(props) {
    super(props);

    const { notice } = props;

    this.state = {
      notice,
    };
  };

  static EMAIL_ADDRESS_REQUIRED = 'Email Address is Required';
  static PASSWORD_REQUIRED = 'Password is Required';
  static VALIDATION_FIELDS = ['emailAddress', 'password'];

  handleKeyPress = e => {
    if ((e.keyCode || e.which) === 13) {
      e.preventDefault();
      this.handleSubmit();
    }
  };

  handleSubmit = e => {
    if (e) { e.preventDefault(); }
    const { VALIDATION_FIELDS } = this.constructor;

    if (VALIDATION_FIELDS.some(key => !this.validateInput(this.state[key]))) {
      this.setState({ validating: true });
      return false;
    }

    const { formAuthenticityToken, signinPath, referrer } = this.props;
    const { emailAddress, password } = this.state;
    const data = { email_address: emailAddress, password, referrer };
    this.setState({ errorMessages: null, notice: false });

    $.ajaxSetup({ beforeSend: xhr => xhr.setRequestHeader('X-CSRF-Token', formAuthenticityToken) });
    $.ajax({
      data,
      method: 'POST',
      url: signinPath,
    }).done(({ redirectTo }) => window.location.href = redirectTo)
      .fail(({ responseJSON: { errorMessages } }) => this.setState({ errorMessages }));
  };

  onChange = (val, input) => this.setState({ [input]: val });
  validateInput = val => !!(val && val.trim());

  render() {
    const { EMAIL_ADDRESS_REQUIRED, PASSWORD_REQUIRED } = this.constructor;
    const { forgotPasswordPath, requestAccount, signinAsVenue, signupPath } = this.props;
    const { emailAddress, password, errorMessages, notice, validating } = this.state;

    return (
      <div className='row'>
        <div className='col-md-8 hidden-sm hidden-xs splash' />
        <div className='col-md-4 col-sm-12 col-xs-12 form signin-form'>
          { notice &&
              <Alert className='alert-success' dismissible='true' onDismiss={() => this.setState({ notice: false })}>{notice}</Alert> }
          { errorMessages &&
              <Alert className='alert-danger' dismissible='true' onDismiss={() => this.setState({ errorMessages: '' })}><div dangerouslySetInnerHTML={{ __html: errorMessages }} /></Alert> }
          <div className='logo'><img src={KapowImage} /></div>
          <div className='form-entry'>
            <div className='form-group'>
              <FloatingLabelInputField defaultVal={emailAddress}
                                       focusOnLoad
                                       id='emailAddress'
                                       isValid={this.validateInput}
                                       label='Email'
                                       onChange={this.onChange}
                                       onKeyPress={this.handleKeyPress}
                                       validationMessage={val => validating && !this.validateInput(val) &&
                                                                   <div className='validation-message'>{EMAIL_ADDRESS_REQUIRED}</div>} />
            </div>
            <div className='form-group'>
              <FloatingLabelInputField defaultVal={password}
                                       id='password'
                                       isValid={this.validateInput}
                                       label='Password'
                                       onChange={this.onChange}
                                       onKeyPress={this.handleKeyPress}
                                       type='password'
                                       validationMessage={val => validating && !this.validateInput(val) &&
                                                                   <div className='validation-message'>{PASSWORD_REQUIRED}</div>} />
            </div>
          </div>
          <div className='forgot-password'>
            <a href={forgotPasswordPath} className='forgot-password'>Forgot Password?</a>
          </div>
          <div>
            <input className='btn btn-default btn-block submit'
                   onClick={this.handleSubmit}
                   type='submit'
                   value='Sign In' />
          </div>
          <div className='signup-margin'>
            <span>Don't have a Kapow account? <a href={signupPath}>Sign Up!</a></span>
          </div>
          <div className='venues'>
            <h4>Venues?</h4>
            <a href={signinAsVenue}>Sign In as a Venue</a> or <a href={requestAccount}>Request an Account</a>
          </div>
        </div>
      </div>
    );
  };
};
