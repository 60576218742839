import React from 'react';
import ReactDOM from 'react-dom';
import SignIn from 'components/users/sign_in';
import 'styles/users/sign_in_up';

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('signin');
  const props = JSON.parse(node.getAttribute('data-props'));
  ReactDOM.render(<SignIn {...props} />, node);
});
