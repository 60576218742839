import React from 'react';
import 'styles/shared/floating_label_input_field';

export default class FloatingLabelInputField extends React.Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();

    this.state = {
      active: '',
      dirty: '',
    };
  };

  componentDidMount() {
    if (this.props.focusOnLoad) {
      this.textInput.current.focus();
    }
  };

  setFocusToInput = () => { this.textInput.current.focus(); };

  handleFocus = () => { this.setState({ active: 'active', dirty: false }) };

  handleBlur = () => {
    this.setState({active: ''});
    if (this.textInput.current.value) { this.setState({ dirty: true }); }
  };

  validate = (val, isValid) => {
    if (!isValid(this.textInput.current.value, val)) {
      this.setState({ invalid: 'invalid' });
    } else {
      this.setState({ invalid: '' });
    }
  };

  render() {
    const { type, id, label, defaultVal, onChange, isValid, className, validationMessage, onKeyPress } = this.props;
    const { active, dirty } = this.state;
    const { setFocusToInput, handleFocus, handleBlur } = this;

    return (
        <React.Fragment>
          <div key={label} className={`float-container ${className ? className : ''} ${active ? active : ''} ${ (defaultVal && !isValid(defaultVal, id) || defaultVal === '') ? 'invalid' : ''} ${(defaultVal || dirty) ? 'dirty' : ''}`}>
            <label onClick={e => setFocusToInput(e)}>{label}</label>
            <input type={type || 'text'}
                   id={id}
                   ref={this.textInput}
                   name={id}
                   data-placeholder=''
                   onFocus={e => handleFocus(e)}
                   onBlur={e => handleBlur(e)}
                   onChange={() => { onChange(this.textInput.current.value, id) } }
                   onKeyPress={onKeyPress}
                   defaultValue={defaultVal || ''} />
          </div>
          {validationMessage && validationMessage(defaultVal)}
        </React.Fragment>
    );
  }
};
